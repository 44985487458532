import './customer'
import './customer_location'
import './customer_contact'
import './work_package'
import './contract'

import './ticket'
import './ticket_queue'
import './priority'
import './category'
import './status'
import './settings'
import './project'
import './roles'
import './user'
import './resource'

import './erp/document'
import './erp/warehouse'
import './erp/pallet'
import './erp/supplier'
import './erp/labels'
import './erp/boxes'
import './erp/notepad'
import './erp/dashboard'
import './erp/logs'
import './erp/inventory'
import './erp/product'
import './erp/product_attribute'
import './erp/order'
import './erp/stock'
import './erp/bookings'

import './wms'

import './reporting/'


import './home'
import './teams'
import './admin'

import Search from "./search";
import Header from "./header";

document.addEventListener("DOMContentLoaded", (e) => {
    (document.querySelectorAll("a[data-language]") as NodeListOf<HTMLElement>).forEach((elem) => {
        const newLocale = elem.getAttribute("data-language");
        // @ts-ignore
        const url = document.location.href.replace(`/${currentLocale}`, `/${newLocale}`)
        elem.setAttribute("href", url)
    });

    new Search();
    new Header();
})